
import { Component, Vue } from "vue-property-decorator";
import { ICentreData, IMembreData } from "@/api/types";
import { getCentres } from "@/api/centres";
import { addMembre } from "@/api/membres";

@Component({
  name: "Form",
})
export default class extends Vue {
  //#region Datas
  private list: ICentreData[] = [];
  private newMembre: object = {};
  private postLoading: boolean = false;
  private listLoading: boolean = false;
  private formDisabled: boolean = false;
  private rules = {
    centreid: [
      {
        required: true,
        message: "Veuillez sélectionner un centre",
        trigger: "change",
      },
    ],
    nom: [
      {
        required: true,
        message: "Veuillez renseigner un nom",
        trigger: "blur",
      },
      { min: 3, message: "Minimum 3 caractères", trigger: "blur" },
    ],
    prenom: [
      {
        required: true,
        message: "Veuillez renseigner un prénom",
        trigger: "blur",
      },
      { min: 3, message: "Minimum 3 caractères", trigger: "blur" },
    ],
    email: [
      {
        required: true,
        message: "Veuillez renseigner une adresse mail",
        trigger: "blur",
      },
    ],
  };
  //#endregion

  //#region Lifecycle Hooks
  created() {
    this.getList();
  }
  //#endregion

  //#region Methods
  // Récupération de la liste des centres
  private async getList() {
    this.listLoading = true;
    try {
      const { data } = await getCentres(null);
      this.list = data.items;
    } catch (error) {
    } finally {
      this.listLoading = false;
    }
  }

  // Validation du formulaire
  private beforeSubmit() {
    if (
      (this.$refs["newMembreform"] as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      this.onSubmit();
    } else {
      return false;
    }
  }

  // Envoi du formulaire
  private async onSubmit() {
    this.postLoading = true;
    this.formDisabled = true;
    try {
      const { data } = await addMembre(this.newMembre);
      this.$message({
        message: "Succés",
        type: "success",
        onClose: this.onCancel,
      });
    } catch (error) {
      this.$message({
        message: "Erreur lors de l'ajout.",
        type: "error",
      });
      this.formDisabled = false;
    } finally {
      this.postLoading = false;
    }
  }

  // Annuler et retourner sur la liste des membres
  private onCancel() {
    this.$router.push({ path: "/membres" });
  }
  //#endregion
}
