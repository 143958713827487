import request from '@/utils/request'
import { IMembreData } from "@/api/types";

export const getMembres = (params: any) =>
  request({
    url: '/membres',
    method: 'get',
    params
  })

  export const addMembre = (data: object) =>
  request({
    url: '/membres',
    method: 'post',
    data
  })
